import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import ChangelogSearch from "components/Search/IVWindowsclSearch";
import QRDownloads from "components/Downloads/Mobile_Apps/QRDownloads";
import NavButtons from 'components/Downloads/Mobile_Apps/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision Windows Phone & Metro App Downloads",
  "path": "/Downloads/Mobile_Apps/Windows_Phone/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Software and Documentation Downloads",
  "image": "../D_Mobile_Apps_SearchThumb.png",
  "social": "/images/Search/D_Mobile_Apps_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Download Area' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/D_Downloads_SearchThumb.png' twitter='/images/Search/D_Downloads_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Mobile_Apps/Windows_Phone/' locationFR='/fr/Downloads/Mobile_Apps/Windows_Phone/' crumbLabel="InstarVision" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "mobile-apps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mobile-apps",
        "aria-label": "mobile apps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mobile Apps`}</h2>
    <h3 {...{
      "id": "instarvision-windows-phone--metro-app-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-windows-phone--metro-app-downloads",
        "aria-label": "instarvision windows phone  metro app downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision Windows Phone & Metro App Downloads`}</h3>
    <p>{`Download our free Universal App for Windows 8, Windows 8.1 and Windows 10 from the Microsoft Store. Click the button below, to open the store page or use the QR code to install our app on your Windows tablet or Windows phone (you need to install a QR Code reader App to do so.).`}</p>
    <EuiButton href="https://www.microsoft.com/de-de/store/p/instarvision/9nblggh10wtp" fill mdxType="EuiButton">Microsoft Store</EuiButton>
    <EuiSpacer mdxType="EuiSpacer" />
    <QRDownloads mdxType="QRDownloads" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "windows-app-changelog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows-app-changelog",
        "aria-label": "windows app changelog permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows App Changelog`}</h2>
    <ChangelogSearch mdxType="ChangelogSearch" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      